<template>
  <div>
    <GsDataTable
      ref="table"
      api=""
      endpoint="/tasks"
      appSetting="tasks"
      :headers="headers"
      :sort-by="['name']"
      :sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      @click:row="row_click"
    >
      <!-- <template v-slot:item.details="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              icon
              small
              :to="{
                name: 'Scheduled Report Details',
                params: { taskId: item.taskId },
              }"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi mdi-text-box-search-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("details") }}</span>
        </v-tooltip>
      </template> -->

      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="(actionId) => action_activate(actionId, item)"
        />
      </template>

      <template v-slot:item.enabled="{ item }">
        <v-switch
          dense
          :input-value="item.enabled"
          @click="enabled_change(item, $event)"
        ></v-switch>
      </template>

      <template v-slot:item.name="{ item }">
        <h3 class="subtitle-2">{{ item.name }}</h3>
        <span class="caption">{{ item.description | shortenText }}</span>
      </template>

      <template v-slot:item.cronObject="{ item }">
        <GsCronjobToReadable :cronObject="item | cronObject" />
      </template>

      <template v-slot:item.referenceParameters.inputValues.items="{ item }">
        <span>
          {{ item.$calculated.items }}
          <br />
          {{ item.$calculated.groups }}
        </span>
      </template>

      <template v-slot:item.lastRun="{ item }">
        <span>{{ item.lastRun | getDisplayDate | empty }}</span>
      </template>

      <template v-slot:item.nextRun="{ item }">
        <span>{{ item.nextRun | getDisplayDate | empty }}</span>
      </template>

      <template v-slot:item.startTime="{ item }">
        <span>{{
          dateCorrection(item.startTime) | getDisplayDate | empty
        }}</span>
      </template>

      <template v-slot:item.endTime="{ item }">
        <span>{{ item.endTime | getDisplayDate | empty }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>
          {{ item.createdAt | getDisplayDate }}
          <br />
          ({{ item.$calculated.$createdBy | empty }})
        </span>
      </template>

      <template v-slot:item.updatedAt="{ item }">
        <span v-if="item.updatedAt">
          {{ item.updatedAt | getDisplayDate }}
          <br />
          ({{ item.$calculated.$updatedBy | empty }})
        </span>
      </template>

      <!-- <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-4 my-2"
              @click="showSchedulerSimulator(item)"
              v-bind="attrs"
              v-on="on"
              >mdi mdi-view-list</v-icon
            >
          </template>
          <span>{{ $t("show_next_runs") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-4 my-2"
              @click="editTask(item)"
              v-bind="attrs"
              v-on="on"
              >mdi mdi-pencil</v-icon
            >
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-4 my-2"
              @click="deleteTask(item.taskId)"
              v-bind="attrs"
              v-on="on"
              >mdi mdi-delete</v-icon
            >
          </template>
          <span>{{ $t("delete") }}</span>
        </v-tooltip>
      </template> -->

      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </GsDataTable>
    <!-- create/edit task -->
    <v-dialog
      v-if="dialogReportCreatorVisible"
      :value="true"
      max-width="880px"
      max-height="100%"
      persistent
    >
      <GsReportCreator
        ref="reportCreatorComponent"
        @close="closeReportCreator"
      />
    </v-dialog>
    <!-- run simulator -->
    <v-dialog v-model="schedulerSimulatorVisible" max-width="300px">
      <v-card v-if="selectedItem">
        <v-toolbar color="primary" dark>{{ $t("next_runs_title") }}</v-toolbar>
        <div class="pa-2">
          <GsSchedulerSimulator
            :scheduleConfig="scheduleConfig"
            :taskName="selectedItem.name"
            :compact="false"
          />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="schedulerSimulatorVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import GsCronjobToReadable from "@/components/GsCronjobToReadable";
import GsSchedulerSimulator from "@/components/GsSchedulerSimulator";
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";
import GsReportCreator from "@/components/GsReportCreator";

const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const { isUserAdmin } = require("ngt-frontend-core").permissions;

export default {
  name: "GsReportPlanTable",
  props: {
    reportType: {
      type: String,
      default: null,
    },
    filterStr: {
      type: String,
      default: null,
    },
  },
  components: {
    GsCronjobToReadable,
    GsSchedulerSimulator,
    GsDataTable,
    GsActionsMenu,
    GsReportCreator,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("active"),
          align: "center",
          value: "enabled",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
          width: 200,
          sortable: true,
        },
        {
          text: this.$t("report_type"),
          value: "$calculated.$templateName",
          width: 150,
          sortable: false,
        },
        {
          text: this.$t("assets"),
          value: "referenceParameters.inputValues.items",
          width: 260,
          sortable: false,
        },
        {
          text: this.$t("last_run_at"),
          value: "lastRun",
          width: 150,
          sortable: true,
        },
        {
          text: this.$t("next_run_at"),
          value: "nextRun",
          width: 160,
          sortable: true,
        },
        {
          text: this.$t("repeats"),
          value: "cronObject",
          width: 140,
          sortable: false,
        },
        {
          text: this.$t("start_date"),
          value: "startTime",
          width: 160,
          sortable: true,
        },
        {
          text: this.$t("end_date"),
          value: "endTime",
          width: 170,
          sortable: true,
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 150,
          sortable: true,
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: 150,
          sortable: true,
        },
      ],
      dialogReportCreatorVisible: false,
      schedulerSimulatorVisible: false,
      scheduleConfig: null,
      initialized: false,
      actions: [
        "details",
        "show_next_runs",
        "run_immediately",
        "edit",
        "delete",
      ],
      selectedItem: null,
    };
  },
  computed: {},
  watch: {
    filterStr: {
      deep: true,
      handler() {
        if (!this.initialized) return;
        this.$refs.table.goToFirstPage();
      },
    },
  },
  mounted() {
    this.dialogReportCreatorVisible = false;
    this.schedulerSimulatorVisible = false;
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      if (this.reportType === "valid") {
        params.url += `&filter=nextRun:gte:${dayjs().toISOString()}`;
      }

      if (this.reportType === "expired") {
        params.url += `&filter=nextRun:lte:${dayjs().toISOString()}`;
      }

      // params.url += `,ownedBy:eq:${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;

      if (this.filterStr) {
        params.url += "," + this.filterStr.substring("&filter=".length);
      }

      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      const templateIds = params.items.map(
        (p) => p.referenceParameters.templateId
      );
      const templateNames = await this.getTemplateNamesByIds(templateIds);
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$templateName =
          templateNames[item.referenceParameters.templateId];
        // item.$calculated.emailNotificationLocales = await this.GetEmailNotificationLocales(
        //   item.referenceParameters.templateId
        // );
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        item.$calculated.$updatedBy = item?.updatedBy?.name || "";

        const selectedAssets = this.selectedAssetsAndItemsExtract(
          item.referenceParameters
        );
        const selectedDrivers = this.selectedDriversAndItemsExtract(
          item.referenceParameters
        );
        const selectedGeoZones = this.selectedGeoZonesAndItemsExtract(
          item.referenceParameters
        );

        const items = [];
        const groups = [];
        if (selectedAssets?.items) {
          items.push(selectedAssets.items);
        }
        if (selectedAssets?.groups) {
          groups.push(selectedAssets.groups);
        }

        if (selectedDrivers?.items) {
          items.push(selectedDrivers.items);
        }
        if (selectedDrivers?.groups) {
          groups.push(selectedDrivers.groups);
        }

        if (selectedGeoZones?.items) {
          items.push(selectedGeoZones.items);
        }
        if (selectedGeoZones?.groups) {
          groups.push(selectedGeoZones.groups);
        }

        item.$calculated.items = items.join(", ");
        item.$calculated.groups = groups.join(", ");
      }
      return params;
    },
    show_next_runs_click(item) {
      this.selectedItem = item;
      this.schedulerSimulatorVisible = true;
      this.scheduleConfig = {
        schedulingStartTime: dayjs(item.startTime).toISOString(),
        schedulingEndTime: item.endTime,
        schedulingTimeData: {
          hour: item.hour,
          minute: item.minute,
          month: item.month,
          day: item.day,
          dayType: item.dayType,
        },
        timezone: item.timezone,
      };
    },
    async enabled_change(item, event) {
      event.stopPropagation();
      item.enabled = !item.enabled;
      await this.updateTask(item.taskId, { enabled: item.enabled });
    },
    async updateTask(taskId, data) {
      try {
        this.$store.state.loading = true;
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/tasks/${taskId}`;
        await callAPI({
          url,
          method: "PATCH",
          data,
        });
        this.$store.state.loading = false;
      } catch (error) {
        this.$store.state.loading = false;
        this.errorSnackbar(error);
      }
    },
    async edit_click(task) {
      this.dialogReportCreatorVisible = true;
      this.$nextTick(async () => {
        if (this.$refs.reportCreatorComponent) {
          await this.$refs.reportCreatorComponent.edit(task);
        }
      });
    },
    async closeReportCreator() {
      this.dialogReportCreatorVisible = false;
      this.refreshTable();
    },
    async delete_click(item) {
      const taskId = item.taskId;
      const confirmText = `${this.$t("delete_modal")}`;
      const confirmResult = confirm(confirmText);
      if (!confirmResult) return;

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/tasks/${taskId}`;
        const result = await callAPI({ url, method: "DELETE" });
        if (result.status !== 204) return;
        await this.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    dateCorrection(date) {
      return dayjs(date).toISOString();
    },
    details_click(item) {
      this.$router.push_safe({
        name: "Scheduled Report Details",
        params: { taskId: item.taskId },
      });
    },
    async run_immediately_click(item) {
      if (!item.taskId) {
        return;
      }

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/tasks/${item.taskId}/run-immediately`;
        const result = await callAPI({ url, method: "POST" });
        if (result?.data?.jobId) {
          this.$store.state.snackbar = {
            text: this.$t("run_immediately_success", [item.name]),
            color: "success",
            timeout: 5000,
            open: true,
            link: result.data.jobId
              ? `/jobs/list/job/${result.data.jobId}`
              : undefined,
            linkLabel: result.data.jobId
              ? this.$t("report_created_success_link_label")
              : undefined,
          };
        } else {
          this.errorToRunImmediate(item.name);
        }
      } catch {
        this.errorToRunImmediate(item.name);
      }
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "details":
          this.details_click(item);
          break;
        case "show_next_runs":
          this.show_next_runs_click(item);
          break;
        case "run_immediately":
          this.run_immediately_click(item);
          break;
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    row_click(item) {
      this.details_click(item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
