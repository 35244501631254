<template>
  <v-container fluid class="pa-6">
    <!-- toolbar -->
    <v-row>
      <v-btn dark color="#ebac00" class="mr-2" @click="openReportCreator">
        <v-icon small class="mr-2">mdi mdi-clock</v-icon>
        {{ $t("schedule") }}
      </v-btn>
    </v-row>
    <!-- filter -->
    <v-row>
      <GsDataFilter
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </v-row>
    <!-- table -->
    <v-row>
      <h2 class="mb-2">{{ $t("valid_schedules") }}</h2>
      <v-card>
        <GsReportPlanTable
          ref="validSchedules"
          reportType="valid"
          :filterStr="filterStr"
        />
      </v-card>
    </v-row>
    <v-row class="pt-3">
      <h2 class="mb-2">{{ $t("expired_schedules") }}</h2>
      <v-card>
        <GsReportPlanTable
          ref="expiredSchedules"
          reportType="expired"
          :filterStr="filterStr"
        />
      </v-card>
    </v-row>
    <!-- create report -->
    <v-dialog
      v-if="dialogReportCreatorVisible"
      :value="true"
      max-width="880px"
      max-height="100%"
      persistent
    >
      <GsReportCreator
        ref="reportCreatorComponent"
        @close="closeReportCreator"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import GsReportCreator from "@/components/GsReportCreator";
import GsReportPlanTable from "@/components/GsReportPlanTable";
import { GsDataFilter } from "ngt-frontend-core";

export default {
  name: "ReportsPlan",
  components: {
    GsReportCreator,
    GsReportPlanTable,
    GsDataFilter,
  },
  data() {
    return {
      appSettingKey: "tasks",
      templates: null,
      dialogReportCreatorVisible: false,
      filters: [
        {
          field: "createdBy",
          component: "GsSelectFilter",
          params: {
            options: [],
          },
        },
      ],
      filterStr: "",
      initialized: false,
    };
  },
  async mounted() {
    this.dialogReportCreatorVisible = false;
    this.initialized = false;

    // set view
    if (this.$router.history.current.params.viewMode) {
      switch (this.$router.history.current.params.viewMode) {
        case "schedule":
          this.listViewMode = 1;
          this.openReportCreator();
          break;
      }
    }

    await this.fillCreatedByOptions();
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    refreshTables() {
      this.$refs.validSchedules.refreshTable();
      this.$refs.expiredSchedules.refreshTable();
    },
    openReportCreator() {
      this.dialogReportCreatorVisible = true;
      setTimeout(() => {
        const initStartDate = dayjs()
          .add(1, "hours")
          .startOf("hour")
          .toISOString();
        this.$refs.reportCreatorComponent.create(initStartDate);
      }, 0);
    },
    closeReportCreator() {
      this.refreshTables();
      this.dialogReportCreatorVisible = false;
    },
    onFilterChanged() {
      this.filterStr = this.$refs.dataFilter.getFilter();
    },
    async fillCreatedByOptions() {
      const data = await this.getUsers();
      if (!data) return;
      this.filters.find((p) => p.field === "createdBy").params.options =
        data.map((p) => ({ value: p._uri, text: p.name }));
    },
  },
};
</script>

<style lang="scss">
th {
  text-transform: uppercase;
}
</style>
